
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';
 
@Component
export default class RelatorioDespesaChave extends PageBase { 
    service = new RelatorioService();
    lista: Model[] = [];
    overlay: boolean = false; 

    filter:any={};

    mounted() {
    this.filter = this.$route.query;
    this.overlay = true;  

    this.service.ListarRecibiveisProprietarios(this.filter.empreendimentoId, this.filter.empresaId, this.filter.isBaixaAutomatica, this.filter.dataVencimentoInicial, this.filter.dataVencimentoFinal, this.filter.dataPagamentoInicial, this.filter.dataPagamentoFinal, this.filter.isTaxaCorretor).then(
        res => {
            this.lista = res.data; 
        }, 
        err => {
            if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } 
            else {
                this.$swal("Aviso", err.response.data, "error");
            }
        } 
        ).finally(() =>{this.overlay = false});
    } 
}

class Model {
    proprietarios = [
        {nome: "", parcelas:[]}
    ];
    totalValorTaxaAdm:number = 0;
    totalValorPago:number = 0;
    totalRecebido:number = 0;
    totalValorTaxaCorretor:number = 0;
}
